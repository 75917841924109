<template>
  <div class="third container-mini">
    <!-- <div class="onlyMobile third-top"></div> -->
    <div class="third-wrapper container-mini">
      <div class="third-wrapper-bg">
        <div class="third-wrapper__bg wow" data-wow-offset="300"></div>
        <div class="third-wrapper__bg-back wow" data-wow-offset="300"></div>
      </div>
      <div class="third-bottom">
        <section class="third-bottom__left wow" data-wow-offset="100">
          <h2 class="third-bottom__left-title">
            О проекте
          </h2>
          <p class="third-bottom__left-text">
            Будущим жильцам доступен огромный выбор различных планировок,
            подземный паркинг, который убережет вашего «железного коня» от всех
            невзгод, увеличенные окна, высокие потолки, а также smart замки
            последнего поколения на входных дверях
          </p>
        </section>
        <div class="third-bottom__right wow">
          <Slider v-if="showSlider" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from 'wowjs'

export default {
  name: 'ThirdSection',
  components: {
    Slider: () => import('@/components/molecules/Slider/index.vue'),
  },
  data() {
    return {
      showSlider: true,
    }
  },
  mounted() {
    var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
    })
    wow.init()
  },
}
</script>

<style lang="scss" scoped>
.third {
  width: 100%;
  position: relative;
  height: fit-content;
  overflow: hidden;

  &-wrapper {
    position: relative;
    height: 1016px;
    border-radius: 40px;
    width: 100%;

    &-bg {
      @include mobile {
        width: 100%;
        height: 250px;
        overflow: hidden;
        position: relative;
      }
    }

    &__bg-back {
      position: absolute;
      left: 0;
      bottom: 0;
      background: url('../../../../assets/images/third/main-3.png') center
        center / cover no-repeat;
      height: 1016px;
      width: 100%;
      transform: translateY(320px);

      animation: goTop 2s ease-in;

      @keyframes goTop {
        from {
          transform: translateY(0);
        }
        to {
          transform: translateY(320px);
        }
      }

      @include mobile {
        top: 0;
        bottom: auto;
        height: 250px;
        width: 100%;
        z-index: -1;
        transform: translateY(50px);

        @keyframes goTop {
          from {
            transform: translateY(0);
          }
          to {
            transform: translateY(50px);
          }
        }
      }
    }

    &__bg {
      position: absolute;
      z-index: 10;
      height: 1016px;
      width: 100%;
      background: url('../../../../assets/images/third/main-3-withoutbg.png')
        center center / cover no-repeat;
      bottom: 0;
      left: 0;

      @include mobile {
        position: static;
        z-index: 10;
        height: 250px;
      }
    }

    justify-content: flex-end;
    @include flex-column();

    @include mobile {
      height: fit-content;
      justify-content: flex-start;
      background: none;
      position: static;
    }

    &.container-mini {
      @include mobile {
        padding: 0 5px;
      }
    }
  }

  &-top {
    width: 100%;
    height: 250px;
    background: url('../../../../assets/images/third/main.png') center center /
      cover no-repeat;
  }

  &-bottom {
    gap: 44px;
    @include flex-row();
    padding: 60px 42px;
    z-index: 20;

    @include tablet-portrait {
      flex-direction: column;
    }
    @include mobile {
      gap: 14px;
      padding: 14px 0 0;
    }

    &__left {
      max-width: 668px;
      animation: manifestation1 0.8s ease-in;

      &-title {
        padding-bottom: 36px;
        @include font-styles(48px, 58px, 700, $white, 0, $font-third);

        @include mobile {
          padding-bottom: 14px;
          @include font-styles(28px, 32px, 700, $c-dark, 0, $font-third);
        }
      }
      &-text {
        @include font-styles(22px, 30px, 600);

        @include mobile {
          text-align: left;
          @include font-styles(14px, 20px, 400, $c-gray, 0, $font-second);
        }
      }
    }
    &__right {
      align-self: flex-end;
      width: fit-content;
      animation: manifestation1 1s ease-in;

      @include tablet-portrait {
        align-self: flex-start;
      }
    }
  }
}
</style>
